import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menu } from '../reducers/actions/menuaction';
import { ChevronRight, HouseDoor, Gear, Dropbox, BoxArrowRight, Wallet2 } from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import pkg from '../../package.json';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
    const dispatch = useDispatch();
    const menua = useSelector((state) => state.menu.userData);
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // Helper function to determine if a menu item is active
    const isActive = (path) => location.pathname === path;

    // Function to handle navigation and close menu
    const handleLinkClick = () => {
        dispatch(menu());
    };

    const logoutFunction = () =>{
        localStorage.removeItem("user");
        // navigate("/signin");
      }

    return (
        <div className="sidebar-wrap sidebar-overlay">
            <div className="closemenu text-muted" onClick={() => dispatch(menu())}>Close Menu</div>
            <div className="sidebar">
                {/* User information */}
                <div className="row my-3">
                    <div className="col-12 profile-sidebar">
                        <div className="clearfix"></div>
                        <div className="circle small one"></div>
                        <div className="circle small two"></div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 194.287 141.794"
                            className="menubg"
                        >
                            <defs>
                                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="#09b2fd" />
                                    <stop offset="1" stopColor="#6b00e5" />
                                </linearGradient>
                            </defs>
                            <path
                                id="menubg"
                                d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                transform="translate(-503.892 -111.404)"
                                fill="url(#linear-gradient)"
                            />
                        </svg>

                        <div className="row mt-3">
                            <div className="col-auto">
                                <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                    <img src="assets/images/user1.jpg" alt="" className="rounded-18" />
                                </figure>
                            </div>
                            <div className="col px-0 align-self-center">
                                <h5 className="mb-2">{menua.data && `${menua.data.firstname} ${menua.data.lastname}`}</h5>
                                <p className="text-muted size-12">
                                    {menua.data && menua.data.country}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* User menu navigation */}
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/dashboard') ? 'active' : ''}`} to="/dashboard" onClick={handleLinkClick}>
                                    <div className="avatar avatar-40 icon">
                                        <HouseDoor />
                                    </div>
                                    <div className="col">{t('dashboard')}</div>
                                    <div className="arrow">
                                        <ChevronRight />
                                    </div>
                                </Link>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <Link className={`nav-link ${isActive('/walletset') ? 'active' : ''}`} to="/walletset" onClick={handleLinkClick}>*/}
                            {/*        <div className="avatar avatar-40 icon">*/}
                            {/*            <Wallet2 />*/}
                            {/*        </div>*/}
                            {/*        <div className="col">Wallet Settings</div>*/}
                            {/*        <div className="arrow">*/}
                            {/*            <ChevronRight />*/}
                            {/*        </div>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/settings') ? 'active' : ''}`} to="/settings" onClick={handleLinkClick}>
                                    <div className="avatar avatar-40 icon">
                                        <Gear />
                                    </div>
                                    <div className="col">{t('settings')}</div>
                                    <div className="arrow">
                                        <ChevronRight />
                                    </div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/terms') ? 'active' : ''}`} to="/terms" onClick={handleLinkClick}>
                                    <div className="avatar avatar-40 icon">
                                        <Dropbox />
                                    </div>
                                    <div className="col">{t('terms')}</div>
                                    <div className="arrow">
                                        <ChevronRight />
                                    </div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${isActive('/logout') ? 'active' : ''}`} to="/signin" onClick={logoutFunction}>
                                    <div className="avatar avatar-40 icon">
                                        <BoxArrowRight />
                                    </div>
                                    <div className="col">{t('logout')}</div>
                                    <div className="arrow">
                                        <ChevronRight />
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Support and help */}
                <div className="row">
                    <div className="col-12 mt-4">
                        <div className="form-group">
                            <Link to="/support" className="version btn-default btn-lg btn-block shadow-sm" onClick={handleLinkClick}>
                                <i className="bi bi-life-preserver size-22 mx-2"></i>
                                <span>{pkg.name} ({pkg.version})</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
