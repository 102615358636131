import {MENU, MENUMODAL, TOPBAR, USERDATA, PRICES, PINVERIFY, TRANSFER, BALANCE} from '../actions/menuaction';

const initialState = {
    isMenuOpen: false,
    isModalOpen: false,
    isTopBarOpen: false,
    userData: false,
    prices: false,
    pinverify: false,
    transfer: false,
    balance: {}
};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENU:
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen
            };

        case MENUMODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen
            };
        case TOPBAR:
            return {
                ...state,
                isTopBarOpen: action.payload
            };
        case USERDATA:
            return {
                ...state,
                userData: action.payload
            };
        case PRICES:
            return {
                ...state,
                prices: action.payload
            };
        case PINVERIFY:
            return {
                ...state,
                pinverify: !state.pinverify  
            };
        case TRANSFER:
            return {
                ...state,
                transfer: action.payload
            };
        case BALANCE:
            return {
                ...state,
                balance: action.payload
            }
        default:
            return state;
    }
}

export default menuReducer