import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import Logo from '../../assets/img/logo.png';
import { useTranslation } from 'react-i18next';

const BalanceAndStats = () => {
  const menu = useSelector((state) => state.menu);
  const [balance, setBalance] = useState("0");

  const { t } = useTranslation();
  useEffect(() => {
    // console.log(menu.balance.EDN);
    setBalance(menu.balance.EDN);
    // console.log(balance);
  }, [menu.balance]);

  return (
      <div>
        {/* Balance */}
        <div className="row my-4 text-center">
          <div className="col-12">
            <h1 className="fw-light mb-2" style={{ fontSize: '1.75rem' }}>
              $ {menu.userData ? (parseFloat(balance) * menu.userData.price).toFixed(8) : 0}
            </h1>
            <p className="text-secondary">{t('walletportfolio')}</p>
          </div>
        </div>

        {/* Income Expense */}
        <div className="row mb-4">
          <div className="col-12 col-sm-6 mb-3 mb-sm-0">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <div className="avatar avatar-40 p-1 shadow-sm shadow-success rounded-15">
                      <div className="icons bg-success text-white rounded-12">
                        <img src="/logo.png" alt="" style={{ width: 32, height: 32 }} />
                      </div>
                    </div>
                  </div>
                  <div className="col align-self-center ps-0">
                    <p className="size-10 text-secondary mb-0">{t('coinname')}</p>
                    <p style={{ fontSize: '1rem', fontWeight: '500' }}>
                      {/*{menu.userData ? menu.balance['EDN'] : 0}*/}{balance}
                      {t('symbol')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <div className="avatar avatar-40 p-1 shadow-sm shadow-danger rounded-15">
                      <div className="icons bg-danger text-white rounded-12">
                        <img src="/usdlogo.png" alt="usd" style={{ width: 32, height: 32 }} />
                      </div>
                    </div>
                  </div>
                  <div className="col align-self-center ps-0">
                    <p className="size-10 text-secondary mb-0">{t('usd')}</p>
                    <p style={{ fontSize: '1rem', fontWeight: '500' }}>
                      $ {menu.userData && menu.userData.price ? menu.userData.price.toFixed(8) : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default BalanceAndStats;
