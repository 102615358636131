import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import {menuModal, pinverify} from '../reducers/actions/menuaction'
import { ArrowDownLeft, ArrowUpRight, ArrowLeftRight, Copy } from 'react-bootstrap-icons';
import QRCode from "react-qr-code";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { postHelper } from '../helpers/apiHelper';
import { fetchData } from '../helpers/security';
import { useTranslation } from 'react-i18next';
import {coins} from '../constants/coins';

const VerifyModal = () => {
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pin, setPin] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);

  useEffect(() => {
      setPin(['', '', '', '', '', '']);
    //   console.log(menu)
  },[menu.pinverify])
  const handleChange = (e, index) => {
    const value = e.target.value;

    // Allow only numeric input and limit to one character
    if (/^\d$/.test(value)) {
        const newPin = [...pin];
        newPin[index] = value;
        setPin(newPin);

        // Move focus to the next input field
        if (index < 5) {
            inputRefs.current[index + 1].focus();
        }
    }
};

const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
        const newPin = [...pin];
        
        // If the current input is not empty, clear it
        if (newPin[index] !== '') {
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            // If the current input is already empty, move focus to the previous input
            inputRefs.current[index - 1].focus();
            newPin[index - 1] = '';
            setPin(newPin);
        }
    }
};

// Function to find tokenContract by symbol
    const findTokenContractBySymbol = (symbol) => {
        const coin = coins.find((c) => c.symbol === symbol);
        return coin ? coin.tokenContract : null;
    };

const handleSubmit = async (e) => {
    e.preventDefault();
    const token = fetchData('user');
    const pinCode = pin.join('');
    dispatch(pinverify());
    let res;
    if(menu.transfer.coin == "BNB"){
        res = await postHelper('blockchain/transferbnb', {to: menu.transfer.to, amount:menu.transfer.amount, pin:pinCode, coin:menu.transfer.coin}, token);
        console.log(res)
    }else{
        const tokenContract = findTokenContractBySymbol(menu.transfer.coin);
        console.log(tokenContract);
        res = await postHelper('blockchain/transferbep20', {to: menu.transfer.to, amount:menu.transfer.amount, pin:pinCode, coin:tokenContract}, token);
        console.log(res)
    }

    
    if(res.success){
        // res.message.result
        // toast.success("Transaction completed with Txid : "+res.message.result);
        toast("Transaction completed with Txid : "+res.message.transactionHash, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
        })
    }else{
        // error
        toast(res.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
        })
    }

    
}

  return (
    <>
    <Modal
      show={menu.pinverify}
      onHide={() => dispatch(pinverify())} // Add a function to close the modal if needed
      centered
      scrollable
      dialogClassName="modal-sm"
      backdrop={true}
    >
      <Modal.Body>
        <h1 className="mb-4" style={{ fontSize: 25 }}>
          <span className="text-secondary fw-light">{t('transaction')}</span>
          <br />
          PIN
        </h1>
        
        <div className="text-center">
        <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between mb-4">
                    {pin.map((digit, index) => (
                        <input
                            key={index}
                            type="password"
                            inputMode="numeric"            // Opens numeric keypad on mobile
                            pattern="\d*" 
                            className="form-control text-center mx-1"
                            style={{ width: '30px' }}
                            value={digit}
                            maxLength="1"
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                        />
                    ))}
                </div>
                
                <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg">
                        {t('send')}
                    </button>
                </div>
            </form>
        </div>
        
      </Modal.Body>
    </Modal>

    <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </>
  );
};

export default VerifyModal;
