import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import '../assets/css/style.css';
import Header from '../components/Header';
import BalanceAndStats from '../components/home/Balnce';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import MenuModal from '../components/quickmenu';
import VerifyModal from '../components/verifysend';
import ConnectionSwiper from '../components/home/coins';
import {Outlet, useLocation, Link, useNavigate} from "react-router-dom";
import withSessionCheck from '../middleware/withauthcheck';
import { postHelper, priceHelper } from '../helpers/apiHelper';
import { fetchData } from '../helpers/security';
import { ToastContainer, toast } from 'react-toastify';
import {getBNBBalance, getBEP20Balance} from '../helpers/web3Helper';
import {coins} from '../constants/coins';

function Userlayout() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const initialized = useRef(false)
  const menu = useSelector((state) => state.menu)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = fetchData('user');
    const fetch = async () => {
        const res = await postHelper('user/userdata', {}, token);
        if (res.type === "auth") {
            navigate('/sessionexpired');
        } 
        if (res.error) {
            toast(res.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            const prices = await priceHelper();
            const bal = await balances(res.data.address);
            console.log(res.data.address);
            // if (isMounted) {
                dispatch({ type: 'PRICES', payload: prices });
                dispatch({ type: 'USERDATA', payload: res });
                dispatch({ type: 'BALANCE', payload: bal });

            // }
        }
    };

    if (!token) {
        navigate('/signin');
    } else {
      if(!initialized.current)  {
        initialized.current = true;
        fetch();
      }
        
    }

}, []);

    const balances = async (address) => {
        // Initialize the balances object
        let balances = {};

        // Fetch BNB balance
        const bnbBalance = await getBNBBalance(address);
        balances["BNB"] = bnbBalance;

        // Fetch token balances
        const tokenBalances = await Promise.all(
            coins.map(async (coin) => {
                if (coin.isToken) {
                    const tokenBalance = await getBEP20Balance(coin.tokenContract, address);
                    return { [coin.symbol]: tokenBalance };
                }
                return null;
            })
        );

        // Combine BNB and token balances into one object
        tokenBalances.forEach((balance) => {
            if (balance) {
                Object.assign(balances, balance);
            }
        });

        return balances;
    };



    const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  return (
    <div className={`body-scroll ${menu.isMenuOpen ? 'menu-open' : ''}`} data-page="index">
      

      {/* Sidebar main menu */}
      <Sidebar />
      {/* Sidebar main menu ends */}

      <main className="h-100" style={{minHeight: '415px', paddingTop: '85.7188px', paddingBottom: '70px'}}>
        <Header />
        <div className="main-container container">
          <Outlet />
        </div>
      </main>

      {/* Footer starts */}
      <Footer />
      {/* Footer Ends */}

      <MenuModal />
      <VerifyModal />

    </div>
  );
}

export default withSessionCheck(Userlayout) //Userlayout