// src/App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import FinwallApp from './containers/Home';
import { useDispatch } from 'react-redux';
import { topBar } from './reducers/actions/menuaction';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Authlayout from './layouts/Authlayout';
import Otherlayout from './layouts/OtherLayout';
import Userlayout from './layouts/userlayout';
import Signup from './containers/auth/Signup';
import SignIn from './containers/auth/Signin';
import PinEntry from './containers/auth/Pin';
import MnemonicEntry from './containers/auth/Mnemonic';
import MnemonicSubmission from './containers/auth/recover';
import LandingPage from './containers/Landing';
import ErrorPage from './containers/404';
import MnemonicSave from './containers/seed';
import SessionExpired from './containers/session';
import Welcome from './containers/regsuccess';
import SwapPage from './containers/Swap';
import SendPage from './containers/send';
import ReceivePage from './containers/receive';
import TermsAndConditions from './containers/terms';
import Transactions from './containers/transactions';
import QrScannerPage from './containers/scanner';
import TransactionPin from './containers/auth/transactionpin';
import SetTransactionPin from './containers/auth/settransactionpin';
import AccountSettings from './containers/profile';
import CameraAccess from './containers/scannera';
import Tfaverify from './containers/auth/Tfa';
import SetPass from './containers/auth/resetpass';
import WalletManager from './containers/WalletSettings';
const App = () => {
  const dispatch = useDispatch();
  const handleScroll = () => {
    const scrollPosition = window.scrollY; // Current scroll position
    const triggerPoint = 40; // Trigger point (in pixels) from the top of the page

    if (scrollPosition >= triggerPoint) {
      dispatch(topBar(true));
      // Call your function here
      // console.log("You've scrolled past 100px!");
    } else {
      dispatch(topBar(false));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <Routes>
        
        
        
        

        <Route element={<Userlayout />} >
        <Route path="/dashboard" element={<FinwallApp />} />
        <Route path="/swap" element={<SwapPage />} />
        <Route path="/send" element={<SendPage />} />
        <Route path="/receive" element={<ReceivePage />} />
        <Route path="/history" element={<Transactions />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/scanner" element={<QrScannerPage />} />
        <Route path="/pinverify" element={<TransactionPin />} />
        <Route path="/setpin" element={<SetTransactionPin />} />
        <Route path="/settings" element={<AccountSettings />} />
        <Route path="/walletset" element={<WalletManager />} />

        </Route>
        
        <Route element={<Authlayout />} >
          <Route exact path="/" element={<SignIn />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/signin' element={<SignIn />} />
          <Route path='/recover' element={<MnemonicSubmission />} />  
          <Route path='/scan' element={<CameraAccess />} />
          <Route path="/sessionexpired" element={<SessionExpired />} />
        </Route>

        <Route element={<Otherlayout />} >
          <Route path='/pin' element={<PinEntry />} />
          <Route path='/mnemonic' element={<MnemonicEntry />} />
          <Route path='/seed' element={<MnemonicSave />} />
          <Route path='/success' element={<Welcome />} />
          <Route path='/tfa' element={<Tfaverify />} />
          <Route path="/setpass" element={<SetPass />} />
        </Route>

        {/* 404 */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>



    </Router>
      // <FinwallApp />
  );
};

export default App;
