import React, { useState, useEffect } from "react";

const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    // Function to calculate time ago
    const calculateTimeAgo = (time) => {
        const now = new Date();
        const past = new Date(time * 1000); // Convert timestamp to milliseconds if it's in seconds
        const diffInSeconds = Math.floor((now - past) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} seconds ago`;
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        } else {
            const days = Math.floor(diffInSeconds / 86400);
            return `${days} day${days > 1 ? "s" : ""} ago`;
        }
    };

    // Update time ago every minute
    useEffect(() => {
        const updateAgo = () => setTimeAgo(calculateTimeAgo(timestamp));
        updateAgo();
        const interval = setInterval(updateAgo, 60000); // Update every minute

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [timestamp]);

    return <span>{timeAgo}</span>;
};

export default TimeAgo;
