
// coinType = 0 for NON EVM crypto, 1 for EVM based crypto
const coins = [
    {
        id: 0,
        name: "EDENCOIN",
        symbol: "EDN",
        image: "/logo.png",
        price: 0,
        change: 0,
        volume: 0,
        network: "BSC",
        isToken: true,
        coinType: 0,
        tokenContract: "0x5c1826E96F6C5aa6b0A01b20cF6feaB192b6aE5e"
    },
    {
        id: 3,
        name: "Binance Chain",
        symbol: "BNB",
        image: "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "BSC",
        isToken: false,
        coinType: 1,
        tokenContract: ""
    },
    {
        id: 8,
        name: "Tether",
        symbol: "USDT",
        image: "https://cryptologos.cc/logos/tether-usdt-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "BSC",
        isToken: true,
        coinType: 1,
        tokenContract: "0x55d398326f99059ff775485246999027b3197955"
    },
    
]

const coinHistoryUrl = (coin) => {
    return `https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=usd&days=7`;
}


export { coins, coinHistoryUrl }