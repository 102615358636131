import {Web3} from "web3";

const web3 = new Web3('https://bsc-dataseed1.binance.org/');


// Token ABI (minimal ABI for ERC-20/BEP-20 balanceOf function)
const tokenABI = [
    {
        constant: true,
        inputs: [{ name: '_owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: 'balance', type: 'uint256' }],
        type: 'function'
    },
    {
        "inputs": [
            { "internalType": "uint256", "name": "_projectId", "type": "uint256" },
            { "internalType": "address", "name": "_token", "type": "address" },
            { "internalType": "uint256", "name": "_amount", "type": "uint256" }
        ],
        "name": "depositToken",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];


/**
 * Get the BNB balance of an address
 * @param {string} address - The wallet address
 * @returns {Promise<string>} - The BNB balance in ether
 */
async function getBNBBalance(address) {
    try {
        const balance = await web3.eth.getBalance(address);
        return web3.utils.fromWei(balance, 'ether'); // Convert Wei to Ether
    } catch (error) {
        console.error('Error fetching BNB balance:', error);
        return null;
    }
}

/**
 * Get the BEP-20 token balance of an address
 * @param {string} tokenContractAddress - The BEP-20 token contract address
 * @param {string} address - The wallet address
 * @returns {Promise<string>} - The token balance
 */
async function getBEP20Balance(tokenContractAddress, address) {
    try {
        const tokenContract = new web3.eth.Contract(tokenABI, tokenContractAddress);
        const balance = await tokenContract.methods.balanceOf(address).call();
        return web3.utils.fromWei(balance, 'ether'); // Convert Wei to Ether (if applicable)
    } catch (error) {
        console.error('Error fetching BEP-20 token balance:', error);
        return null;
    }
}

/**
 * Check estimated gas fees for a transaction
 * @param {string} from - Sender's address
 * @param {string} to - Recipient's address
 * @param {string} value - Amount to send (in Ether)
 * @param {string} data - Optional transaction data (e.g., for token transfers)
 * @returns {Promise<object>} - Estimated gas fee details
 */
const checkGasFees = async (from, to, value, data = '') => {
    console.log(from, to, value);

    try {
        // Convert value to Wei
        const amountInWei = web3.utils.toWei(value.toString(), 'ether');

        // Transaction object
        const txObject = {
            from,
            to,
            value: value,//web3.utils.toHex(amountInWei),
            data, // Optional data for contract interactions
        };

        // Estimate gas limit
        const gasLimit = await web3.eth.estimateGas(txObject);
        console.log(gasLimit);

        // Get current gas price
        const gasPrice = await web3.eth.getGasPrice();

        // Calculate total gas fee in Wei
        const totalGasFee = parseFloat(gasPrice * gasLimit) * 3;//web3.utils.toBN(gasPrice).mul(web3.utils.toBN(gasLimit));

        // Convert total gas fee to Ether
        const totalGasFeeInEther = web3.utils.fromWei(totalGasFee, 'ether');

        return {
            gasLimit,
            gasPrice: web3.utils.fromWei(gasPrice, 'gwei') + ' Gwei',
            totalGasFee: totalGasFeeInEther,
        };
    } catch (error) {
        console.error('Error estimating gas fees:', error);
        return { error: error.message };
    }
};



export {getBNBBalance,getBEP20Balance, checkGasFees};